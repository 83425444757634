import * as React from 'react';
import moment from 'moment-timezone';
import AddFavoriteCandidate from '../Favorites/AddFavoriteCandidate';
import { ApplicantsNotes } from "../Notes/ProfileNotes"
import SBSendMessage from "../SBChat/SBSendMessage"

const ApplicantInvitation = ({ applicant, favorites, authenticity_token, notes, load_notes, loaded, timezone }) => {
  const { profile } = applicant;

  const application_date = moment(applicant.created_at)
    .tz(timezone)
    .format('llll');

  return (
    <div className="row gx-5 ManageApplicantContainer">
      <div className={`col-12 col-lg-8 application left border rounded-dd p-0 shadow-sm mb-2`}>
        <div className="row p-3">
          <div className="col-2 p-2">
            <a href="#">
              <img src={profile.avatar_url} className="img-fluid rounded-circle" />
            </a>
          </div>
          <div className="col-8 col-md-6 d-flex flex-column justify-content-center">
            <span className="d-flex flex-row">
              <h2>
                <a href={`/profiles/${profile.id}`} target="_blank">
                  {profile.fullname}
                </a>
                <AddFavoriteCandidate
                  profile={profile}
                  favorited_init={favorites.includes(profile.id)}
                  authenticity_token={authenticity_token}
                />
              </h2>
            </span>
            <ul className="d-flex flex-column flex-sm-row list-unstyled">
              <li className="profile-attribute-bubble rounded px-2 py-1 me-1 mb-1 d-none d-md-block">
                <i className="fa fa-map-marker"></i> {profile.city},{" "}
                {profile.state}
              </li>
              {profile.hourly_rate && (
                <li className="profile-attribute-bubble rounded px-2 py-1 me-1 mb-1 d-none d-md-block">
                  <i className="fa fa-money"></i> ${profile.hourly_rate} / hour
                </li>
              )}
            </ul>
          </div>
          <div className="application-status">
            <span style={{
              position: 'absolute', top: 0, right: 5
            }}>
            </span>
          </div>
        </div>
        <div className="col-12 app-footer bg-light px-4 pt-4 pb-2 rounded-bottom-dd row m-0">
          <ul className="col-12 col-xl-6 app-contacts d-flex flex-column flex-sm-row list-unstyled justify-content-center justify-content-xl-start">
            <li className="mb-2 mb-sm-0">
              <SBSendMessage profileId={profile.id.toString()} />
            </li>
          </ul>
          <ul className="col-12 col-xl-6 app-contacts d-flex flex-row list-unstyled justify-content-center justify-content-xl-end">
            <li className="d-flex flex-column justify-content-center">
              <strong>
                <i className="fa fa-calendar"></i> Invited On {application_date}
              </strong>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-12 col-lg-4 d-flex flex-column justify-content-between mb-2">
        <div className="row p-2 col-12 border rounded-dd flex-grow-1 shadow-sm m-0">
          <ApplicantsNotes
            profile_id={profile.id}
            notes={notes}
            authenticity_token={authenticity_token}
            load_notes={load_notes}
            loaded={loaded}
          />
        </div>
      </div>
    </div>
  )
}

const ApplicantInvitations = ({ applicants, favorites, authenticity_token, notes, load_notes, loaded, timezone }) => {
  return applicants.map(applicant => {
    return ApplicantInvitation({
      applicant,
      favorites,
      authenticity_token,
      notes: notes.filter(note => applicant.profile.id === note.profile_id),
      load_notes: load_notes,
      loaded,
      timezone
    })
  });
}

export default ApplicantInvitations;